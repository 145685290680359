import { template as template_cbb2af38f7fc42ac9f4ffa612752e8da } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_cbb2af38f7fc42ac9f4ffa612752e8da(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
