import { template as template_06b58c5918724e3ba030ade734e22542 } from "@ember/template-compiler";
const FKText = template_06b58c5918724e3ba030ade734e22542(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
