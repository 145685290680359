import { template as template_48d946e365ee4c408005e4ee00a03966 } from "@ember/template-compiler";
const FKControlMenuContainer = template_48d946e365ee4c408005e4ee00a03966(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
